.h-screen {
  height: 80vh;
}

.w-400 {
  width: 400px;
}

input,
select {
  height: 40px;
  border: 2px solid gray;
  width: 100%;
  padding-left: 20px;
}
.ant-form-item {
  margin: 0px !important;
}

.card {
  border: 1px solid gray;
  box-shadow: 0 0 1px rgba(128, 128, 128, 0.205);
  border-radius: 0 !important;
}

.text-2xl {
  font-size: 30px !important;
}
.text-xl {
  font-size: 25px !important;
}
.text-lg {
  font-size: 20px !important;
}
.text-md {
  font-size: 18px !important;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}

.primary-btn {
  background-color: var(--primary) !important;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.disabled-btn {
  background-color: gray !important;
  color: white;
  border: none !important;
  padding: 10px 20px;
  cursor: not-allowed !important;
}

.secondary-btn {
  background-color: var(--secondary);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.outlined {
  border: 2px solid var(--primary) !important;
  color: var(--primary) !important;
  background-color: white !important;
}

.spinner-parent {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.486);
  z-index: 10000;
}
.spinner-border {
  color: white;
  height: 80px;
  width: 80px;
}

.text-bold {
  font-weight: 500 !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.layout-parent {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 15px;
  height: 100vh;
  gap: 20px;
  overflow-x: hidden !important;
}
.body {
  width: 100%;
}
.logo {
  color: white;
  font-size: 30px;
}
.role {
  color: white;
  font-size: 16px;
}
.content {
  padding: 10px 0px;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 85vh;
}
.menu-item {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 5px 15px;
  cursor: pointer;
  transform: 0.2s;
  gap: 15px;
}
.active-menu-item {
  border: 2px solid white;
  border-radius: 5px;
}
.menu-item i {
  font-size: 20px;
  margin-right: 10px;
  color: white;
}
.menu-item span {
  color: white;
  font-size: 18px;
}
.menu {
  margin-top: 150px;
}
